var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "energy-config-component table-component" }, [
    _c(
      "div",
      { staticClass: "page-body page-body-margin-left-16" },
      [
        _c(
          "a-card",
          { staticClass: "card-item", attrs: { title: "部门费用分摊" } },
          [
            _c(
              "div",
              {
                staticClass: "table-header-button",
                attrs: { slot: "extra" },
                slot: "extra"
              },
              [
                _c(
                  "jtl-button",
                  {
                    attrs: {
                      type: "primary",
                      "click-prop": _vm.saveConfigClick
                    }
                  },
                  [_vm._v("保存")]
                )
              ],
              1
            ),
            _c("jtl-form", { ref: _vm.JTL.CONSTANT.DEFAULT_FORM_NAME })
          ],
          1
        ),
        _c(
          "a-card",
          { staticClass: "card-item", attrs: { title: "分项/建筑" } },
          [
            _c(
              "div",
              {
                staticClass: "table-header-button",
                attrs: { slot: "extra" },
                slot: "extra"
              },
              [
                _c(
                  "jtl-button",
                  {
                    attrs: {
                      type: "primary",
                      "click-prop": _vm.saveAttributeClick
                    }
                  },
                  [_vm._v("保存")]
                )
              ],
              1
            ),
            _c("div", [_vm._v("关联设备属性范围:")]),
            _c("br"),
            _vm.selectAttributeList
              ? _c("jtl-select-device-attribute-component", {
                  staticClass: "select-device-attribute",
                  attrs: { "is-device-type": true, mode: "multiple" },
                  model: {
                    value: _vm.selectAttributeList,
                    callback: function($$v) {
                      _vm.selectAttributeList = $$v
                    },
                    expression: "selectAttributeList"
                  }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }