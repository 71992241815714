




















import { Component, Ref } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import FormComponent from '@/mixins/form-component';
import { FormControl, FormControlType, FormControlTextModel } from '@/model/form-control';
import { ViewModeType } from '@/model/enum';
import { EnergyService } from '@/service/energy';
import { getDeviceIdAndAttributeKey } from '@/entity-model/device-entity';

class EnergyConfigModel {
    @FormControl({
        label: '线损',
        type: FormControlType.NUMBER,
        step: 0.1,
        required: true,
        message: 'kWh',
        noMessageTooltip: true
    } as FormControlTextModel)
    lineLoss: number = undefined;

    @FormControl({
        label: '单价',
        type: FormControlType.NUMBER,
        step: 0.1,
        required: true,
        message: '元',
        noMessageTooltip: true
    } as FormControlTextModel)
    price: number = undefined;

    toService() {
        return [
            {
                code: 'price',
                value: this.price
            },
            {
                code: 'wire_loss',
                value: this.lineLoss
            }
        ];
    }
}

@Component
export default class EnergyConfigComponent extends BaseComponent {
    @Ref(JTL.CONSTANT.DEFAULT_FORM_NAME)
    jtlForm: FormComponent<EnergyConfigModel>;
    service: EnergyService = new EnergyService();
    selectAttributeList: Array<string> = null;

    mounted() {
        this.service.getEnergyConfig().then(res => {
            const configModel = new EnergyConfigModel();
            configModel.price = _.get(res, '[0].value');
            configModel.lineLoss = _.get(res, '[1].value');
            this.jtlForm.initForm(configModel, ViewModeType.UPDATE);
        });
        this.service.getBuildingDeviceAttributeScopeConfig().then(res => {
            this.selectAttributeList = _.map(res, item => {
                return `${item.deviceTypeId}|${item.deviceTypeAttr}`;
            });
        });
    }
    saveConfigClick() {
        return this.jtlForm.submitForm().then((ret) => {
            this.service.setEnergyConfig(this.jtlForm.formModel.toService()).then(res => {
                this.showMessageSuccess('保存成功');
            });
        }).catch(err => {
            throw err;
        });
    }
    saveAttributeClick() {
        const params = _.map(this.selectAttributeList, item => {
            let { deviceId, attributeKey } = getDeviceIdAndAttributeKey(item);
            return {
                deviceTypeId: deviceId,
                attr: attributeKey
            };
        });
        this.service.setBuildingDeviceAttributeScopeConfig(params).then(res => {
            this.showMessageSuccess('保存成功');
        });
    }
}
